
.logo {
  width: 70px;
  height: 70px;
  padding: 3em;  
}

.header {
  font-size: 32px;
  font-weight: bold;
  color: white;
}

body {
  font-family: "Helvetica Neue", sans-serif;
  background-color: white;
  margin: 0;
  color:  black;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.45rem;
  /* Add a responsive design for smaller screens */
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

header a {
  color: #fff;
  text-decoration: none;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

nav a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: bold;
}

@media (min-width: 480px) {
  .navbar {
    width: 95%;
  }
}

ul, li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}
  /* Add a responsive design for smaller screens */
  @media (max-width: 768px) {
    ul li {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 5em;
  }
}

a:link {
  color: #FF9900;
}

a:visited {
  color: #CC6600;
}

a:active {
  color: #993300;
}


main {
  padding: 5em;
  box-sizing: border-box;
}

h1 {
  font-size: 30px;
  margin: 0;
  font-weight: normal;
  text-align: left;
}

h2 {
  font-size: 24px;
  margin: 0;
  font-weight: normal;
  text-align: left;
}

p {
  margin: 0 0 20px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}

.columns {
  display: flex;
  flex-direction: row;
  padding-bottom: 2.5em;
}

.column {
  flex: 1;
}

.expandable-section {
  width: 100%;
}

.expandable-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  padding-bottom: 1em;
  font-weight: 600;
}

.expandable-section-body {
  padding: 0;
}

@media (max-width: 768px) {
  .columns {
    flex-direction: column;
  }

  .column {
    width: 100%;
  }
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 10px;
}

@media (max-width: 768px) {
  .video-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
}

@media (max-width: 480px) {
  .video-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1, 1fr);
  }
}

.video-grid video {
  max-width: 100%;
  height: auto;
  height: auto;
}


.video-grid video:nth-child(1) {
  grid-column: 1;
  grid-row: 1;
}

.video-grid video:nth-child(2) {
  grid-column: 2;
  grid-row: 1;
}


.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
}

@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
}

@media (max-width: 480px) {
  .image-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(15, 1fr);
  }
}

.image-grid img {
  max-width: 100%;
  height: auto;
  height: auto;
}

.image-grid img:nth-child(1) {
  grid-column: 1;
  grid-row: 1;
}

.image-grid img:nth-child(2) {
  grid-column: 2;
  grid-row: 1;
}

.image-grid img:nth-child(3) {
  grid-column: 3;
  grid-row: 1;
}

.image-grid img:nth-child(4) {
  grid-column: 4;
  grid-row: 1;
}

.image-grid img:nth-child(5) {
  grid-column: 1;
  grid-row: 2;
}

.image-grid img:nth-child(6) {
  grid-column: 2;
  grid-row: 2;
}

.image-grid img:nth-child(7) {
  grid-column: 3;
  grid-row: 2;
}

.image-grid img:nth-child(8) {
  grid-column: 4;
  grid-row: 2;
}

.image-grid img:nth-child(9) {
  grid-column: 1;
  grid-row: 3;
}

.image-grid img:nth-child(10) {
  grid-column: 2;
  grid-row: 3;
}

.image-grid img:nth-child(11) {
  grid-column: 3;
  grid-row: 3;
}

.image-grid img:nth-child(12) {
  grid-column: 4;
  grid-row: 3;
}

.footer {
  padding: 2em;
}

.footer p {
  margin: 0;
  text-align: center;
}

@media (max-width: 768px) {
  .footer {
    padding: 0.5em;
  }
}

.showcase {
  width: 70%;
}

@media (max-width: 768px) {
  .showcase {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .showcase  {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}


.masonry-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem -1rem;
}

.masonry-grid__item {
  width: calc(33.333% - 2rem);
  margin: 1rem;
}

.masonry-grid__item img {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .masonry-grid__item {
    width: calc(50% - 2rem);
  }
}

@media (max-width: 480px) {
  .masonry-grid__item {
    width: 100%;
  }
}

.contact-button {
  background-color: black;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 24px;
  cursor: pointer;

}

.contact-button:hover {
  background-color: grey;
}
